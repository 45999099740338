import { WOW } from 'wowjs';

//=====  WOW active
new WOW().init();

require("landing")
//===== Prealoder

$(window).on('load', function (event) {
    $('.preloader').delay(500).fadeOut(500);
});


//===== Hero

const sitePreviews = $(`.header-hero-image .site-preview`);
sitePreviews.hide();
let heroImageId = 1;
$(`.header-hero-image .site-preview[data-hero-image-id=${heroImageId}]`).fadeIn(10);
const heroImageCount = sitePreviews.length;
setInterval(() => {
  $(`.header-hero-image .site-preview[data-hero-image-id=${heroImageId}]`).fadeOut(500);
    if (heroImageId === heroImageCount) {
        heroImageId = 1;
    } else {
        heroImageId += 1;
    }
  $(`.header-hero-image .site-preview[data-hero-image-id=${heroImageId}]`).delay(50).fadeIn(1000);
}, 6000);
