
$(function () {

    "use strict";

    //===== Prealoder

    $(window).on('load', function (event) {
        $('.preloader').delay(500).fadeOut(500);
    });

    //===== Hero
    const sitePreviews = $(`.header-hero-image .site-preview`);
    sitePreviews.hide();
    let heroImageId = 1;
    $(`.header-hero-image .site-preview[data-hero-image-id=${heroImageId}]`).fadeIn(10);
    const heroImageCount = sitePreviews.length;
    setInterval(() => {
      $(`.header-hero-image .site-preview[data-hero-image-id=${heroImageId}]`).fadeOut(500);
        if (heroImageId === heroImageCount) {
            heroImageId = 1;
        } else {
            heroImageId += 1;
        }
      $(`.header-hero-image .site-preview[data-hero-image-id=${heroImageId}]`).delay(50).fadeIn(1000);
    }, 6000);

    //===== Section Menu Active

    var scrollLink = $('.page-scroll');
    // Active link switching
    $(window).scroll(function () {
        var scrollbarLocation = $(this).scrollTop();

        scrollLink.each(function () {

            var sectionOffset = $(this.hash).offset().top - 73;

            if (sectionOffset <= scrollbarLocation) {
                $(this).parent().addClass('active');
                $(this).parent().siblings().removeClass('active');
            }
        });
    });


    //===== close navbar-collapse when a  clicked

    $(".navbar-nav a").on('click', function () {
        $(".navbar-collapse").removeClass("show");
    });

    $(".navbar-toggler").on('click', function () {
        $(this).toggleClass("active");
    });

    $(".navbar-nav a").on('click', function () {
        $(".navbar-toggler").removeClass('active');
    });


    //===== Back to top

    // Show or hide the sticky footer button
    $(window).on('scroll', function (event) {
        if ($(this).scrollTop() > 600) {
            $('.back-to-top').fadeIn(200)
        } else {
            $('.back-to-top').fadeOut(200)
        }
    });


    //Animate the scroll to yop
    $('.back-to-top').on('click', function (event) {
        event.preventDefault();

        $('html, body').animate({
            scrollTop: 0,
        }, 1500);
    });

});
